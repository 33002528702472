<template>
  <div class="">
    <div class="uk-box-shadow-medium uk-background-default uk-padding-small brd12">
      <div class="">
        <div class="uk-position-relative">
          <img src="/images/bg_video.jpg" alt="">
          <iframe
            :src="`https://www.youtube.com/embed/${video.youtube_id}`"
            class="uk-position-absolute uk-position-top-left uk-width-1-1 uk-height-1-1"
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
          </iframe>
        </div>

      <div class="uk-text-left uk-margin-small fnt-bld">
        {{ video.video_name }}
      </div>
      <div class="uk-grid uk-grid-small uk-margin-small">
        <div v-for="(tag, i) in tags" :key="i">
          <div>
            <div class="cr-pointer show-animation fs14 uk-margin-small">
              #{{ tag.tag_name }}
            </div>
          </div>
        </div>
      </div>

        <div class="uk-grid uk-grid-small uk-margin-small">
          <div class="">
            <div class="fnt-bld fs18 uk-text-left">Категории</div>
            <div class="uk-grid uk-grid-small uk-margin-small">
              <div v-for="(category, i) in categories" :key="i">
                <div class="cr-pointer show-animation badge brd36 cl-wh bg-church fs14 uk-margin-small">
                  {{ category.category_name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="uk-text-left">
          {{ video.video_desc }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        id: '',
        tags: [],
        categories: [],
        video: {},
      }
    },

    created() {
      this.id = this.$route.params.id

      if (this.id) {
        this.$api.post('video/get_by_id', {
          id: this.id
        }).then(rs => {
          this.video = rs.data
        })

        this.$api.post('video/get_list_categories', {
          id: this.id
        }).then(rs => {
          this.categories = rs.data
        })

        this.$api.post('video/get_tags_video', {
          id: this.id
        }).then(rs => {
          this.tags = rs.data
        })
      }

    }
  }
</script>
